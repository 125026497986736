"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.createFetchController = exports.requestController = exports.getResponse = exports.getRequest = exports.RequestMethod = void 0;
const resolveHeaders = (headersData) => __awaiter(void 0, void 0, void 0, function* () {
    const headers = new Headers();
    if (headersData) {
        for (const i of Object.keys(headersData)) {
            // eslint-disable-next-line
            headers.append(i, yield headersData[i]());
        }
    }
    return headers;
});
const defaultResponseResolver = (response) => __awaiter(void 0, void 0, void 0, function* () {
    const responseBody = yield response.text();
    const responseJson = responseBody ? JSON.parse(responseBody) : {};
    switch (response.status) {
        case 401:
            throw new Error(responseJson && responseJson.error_description
                ? String(responseJson.error_description)
                : 'The token is invalid/expired');
        case 200:
        case 204:
            return responseJson;
        case 400:
        case 500:
            return {
                error: responseJson.Message,
            };
        case 404:
            return {
                error: responseJson,
            };
        default:
            return {
                error: 'Something went wrong, please try again',
            };
    }
});
var RequestMethod;
(function (RequestMethod) {
    RequestMethod["Post"] = "POST";
    RequestMethod["Get"] = "GET";
    RequestMethod["Put"] = "PUT";
    RequestMethod["Delete"] = "DELETE";
})(RequestMethod = exports.RequestMethod || (exports.RequestMethod = {}));
function getRequest(url, requestMethod, requestBody, headersData) {
    return __awaiter(this, void 0, void 0, function* () {
        const init = {
            body: requestBody || null,
            headers: yield resolveHeaders(headersData),
            method: requestMethod,
        };
        return new Request(url, init);
    });
}
exports.getRequest = getRequest;
function getResponse(url, requestMethod, requestBody, headersData) {
    return __awaiter(this, void 0, void 0, function* () {
        const request = yield getRequest(url, requestMethod || RequestMethod.Get, requestBody, headersData);
        return fetch(request);
    });
}
exports.getResponse = getResponse;
function requestController(responseResolver, url, requestMethod, requestBody, headersData) {
    return __awaiter(this, void 0, void 0, function* () {
        const response = yield getResponse(url, requestMethod, requestBody, headersData);
        return responseResolver(response);
    });
}
exports.requestController = requestController;
function createFetchController(responseResolver) {
    return (url, requestMethod, requestBody) => requestController(responseResolver || defaultResponseResolver, url, requestMethod, requestBody);
}
exports.createFetchController = createFetchController;
