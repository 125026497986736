"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
class SimpleObservable {
    constructor() {
        this.observers = [];
    }
    subscribe(observer) {
        this.observers.push(observer);
        return () => {
            const index = this.observers.indexOf(observer);
            this.observers.splice(index, 1);
        };
    }
    inform(change) {
        this.observers.forEach((observer) => observer(change));
    }
}
exports.default = SimpleObservable;
