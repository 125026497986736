"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
class Maybe {
    constructor(value) {
        this.value = value;
    }
    map(fn) {
        return new Maybe(this.value !== null && this.value !== undefined ? fn(this.value) : null);
    }
    getOrDefault(defaultValue) {
        return this.value !== null ? this.value : defaultValue;
    }
}
exports.default = Maybe;
