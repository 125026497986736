"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.BGWHITE = exports.BGCYAN = exports.BGMAGENTA = exports.BGBLUE = exports.BGYELLOW = exports.BGGREEN = exports.BGRED = exports.BGBLACK = exports.FGWHITE = exports.FGCYAN = exports.FGMAGENTA = exports.FGBLUE = exports.FGYELLOW = exports.FGGREEN = exports.FGRED = exports.FGBLACK = exports.HIDDEN = exports.REVERSE = exports.BLINK = exports.UNDERSCORE = exports.DIM = exports.BRIGHT = exports.RESET = void 0;
var RESET = "\x1b[0m";
exports.RESET = RESET;
var BRIGHT = "\x1b[1m";
exports.BRIGHT = BRIGHT;
var DIM = "\x1b[2m";
exports.DIM = DIM;
var UNDERSCORE = "\x1b[4m";
exports.UNDERSCORE = UNDERSCORE;
var BLINK = "\x1b[5m";
exports.BLINK = BLINK;
var REVERSE = "\x1b[7m";
exports.REVERSE = REVERSE;
var HIDDEN = "\x1b[8m";
exports.HIDDEN = HIDDEN;
var FGBLACK = "\x1b[30m";
exports.FGBLACK = FGBLACK;
var FGRED = "\x1b[31m";
exports.FGRED = FGRED;
var FGGREEN = "\x1b[32m";
exports.FGGREEN = FGGREEN;
var FGYELLOW = "\x1b[33m";
exports.FGYELLOW = FGYELLOW;
var FGBLUE = "\x1b[34m";
exports.FGBLUE = FGBLUE;
var FGMAGENTA = "\x1b[35m";
exports.FGMAGENTA = FGMAGENTA;
var FGCYAN = "\x1b[36m";
exports.FGCYAN = FGCYAN;
var FGWHITE = "\x1b[37m";
exports.FGWHITE = FGWHITE;
var BGBLACK = "\x1b[40m";
exports.BGBLACK = BGBLACK;
var BGRED = "\x1b[41m";
exports.BGRED = BGRED;
var BGGREEN = "\x1b[42m";
exports.BGGREEN = BGGREEN;
var BGYELLOW = "\x1b[43m";
exports.BGYELLOW = BGYELLOW;
var BGBLUE = "\x1b[44m";
exports.BGBLUE = BGBLUE;
var BGMAGENTA = "\x1b[45m";
exports.BGMAGENTA = BGMAGENTA;
var BGCYAN = "\x1b[46m";
exports.BGCYAN = BGCYAN;
var BGWHITE = "\x1b[47m";
exports.BGWHITE = BGWHITE;