"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.stringTemplate = void 0;
const stringTemplate = (template, isPrefix) => {
    if (typeof template === 'object') {
        return (value, index) => {
            if (index > template.length - 1) {
                return value;
            }
            return isPrefix ? template[index] + value : value + template[index];
        };
    }
    return (value) => (isPrefix ? template + value : value + template);
};
exports.stringTemplate = stringTemplate;
