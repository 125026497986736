"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _reactNative = require("react-native");

//@ts-nocheck
var _default = _reactNative.StyleSheet.create({
  container: {
    fontFaimly: "sans-serif",
    fontWeight: "800",
    fontSize: "40px",
    fontColor: "#000000",
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "100vh",
    background: "transparent"
  }
});

exports.default = _default;